import React from 'react'
import { Container, Row, Col } from '../../../components/basic/Grid'
import ColorSwatch from '../../../components/basic/ColorSwatch'

const Colors = () => {
  return (
    <div>
      <Container>
        <h3>Colors</h3>
        <hr />
        <h5>Brand Colors</h5>
        <Row>
          <Col xs="6" md="4" lg="3">
            <ColorSwatch color="primary" />
            <ColorSwatch color="primary-light" />
            <ColorSwatch color="primary-text">
              Always check the readability of text
            </ColorSwatch>
          </Col>
          <Col xs="6" md="4" lg="3">
            <ColorSwatch color="secondary" />
            <ColorSwatch color="secondary-light" />
            <ColorSwatch color="secondary-text">
              Always check the readability of text
            </ColorSwatch>
          </Col>
          <Col xs="6" md="4" lg="3">
            <ColorSwatch color="tertiary" />
            <ColorSwatch color="tertiary-light" />
            <ColorSwatch color="tertiary-text">
              Always check the readability of text
            </ColorSwatch>
          </Col>
          <Col xs="6" md="4" lg="3">
            <ColorSwatch color="accent" />
            <ColorSwatch color="accent-light" />
            <ColorSwatch color="accent-text">
              Always check the readability of text
            </ColorSwatch>
          </Col>
        </Row>
        <br />
        <h5>Semantic Colors</h5>
        <br />
        <Row>
          <Col xs="6" md="4" lg="3">
            <ColorSwatch color="success" />
            <ColorSwatch color="success-light" />
            <ColorSwatch color="success-text">
              Always check the readability of text
            </ColorSwatch>
          </Col>
          <Col xs="6" md="4" lg="3">
            <ColorSwatch color="info" />
            <ColorSwatch color="info-light" />
            <ColorSwatch color="info-text">
              Always check the readability of text
            </ColorSwatch>
          </Col>
          <Col xs="6" md="4" lg="3">
            <ColorSwatch color="warning" />
            <ColorSwatch color="warning-light" />
            <ColorSwatch color="warning-text">
              Always check the readability of text
            </ColorSwatch>
          </Col>
          <Col xs="6" md="4" lg="3">
            <ColorSwatch color="danger" />
            <ColorSwatch color="danger-light" />
            <ColorSwatch color="danger-text">
              Always check the readability of text
            </ColorSwatch>
          </Col>
        </Row>
        <br />
        <h5>Neutral Colors</h5>
        <br />
        <Row>
          <Col md="4" lg="6">
            <ColorSwatch color="white" />
          </Col>
          <Col xs="6" md="4" lg="3">
            <ColorSwatch color="white-dark-text">
              Always check the readability of text
            </ColorSwatch>
            <ColorSwatch color="white-darker" />
            <ColorSwatch color="white-dark" />
          </Col>
          <Col xs="6" md="4" lg="3">
            <ColorSwatch color="white-light-text">
              Always check the readability of text
            </ColorSwatch>
            <ColorSwatch color="white-light" />
            <ColorSwatch color="white-lighter" />
          </Col>
          <Col md="4" lg="6">
            <ColorSwatch color="black" />
          </Col>
          <Col xs="6" md="4" lg="3">
            <ColorSwatch color="gray-dark-text">
              Always check the readability of text
            </ColorSwatch>
            <ColorSwatch color="gray-darker" />
            <ColorSwatch color="gray-dark" />
          </Col>
          <Col xs="6" md="4" lg="3">
            <ColorSwatch color="gray-light-text">
              Always check the readability of text
            </ColorSwatch>
            <ColorSwatch color="gray-light" />
            <ColorSwatch color="gray-lighter" />
          </Col>
        </Row>
        <br />
        <br />
        <br />
      </Container>
    </div>
  )
}

export default Colors
