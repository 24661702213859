import React from 'react'
import Button from '../../../components/basic/Button'
import { Container } from '../../../components/basic/Grid'
import styles from '../styleguide.scss'

const Buttons = () => {
  return (
    <div>
      <Container>
        <h3>Buttons</h3>
        <hr />
        <h5>Color Variants</h5>
        <Button styles={styles.btn} color="primary">
          primary
        </Button>
        <Button styles={styles.btn} color="secondary">
          secondary
        </Button>
        <Button styles={styles.btn} color="tertiary">
          tertiary
        </Button>
        <Button styles={styles.btn} color="accent">
          accent
        </Button>
        <Button styles={styles.btn} color="success">
          success
        </Button>
        <Button styles={styles.btn} color="info">
          info
        </Button>
        <Button styles={styles.btn} color="warning">
          warning
        </Button>
        <Button styles={styles.btn} color="danger">
          danger
        </Button>
        <Button styles={styles.btn} color="black">
          black
        </Button>
        <Button styles={styles.btn} disabled>
          disabled
        </Button>
        <Button styles={styles.btn} color="link">
          link style
        </Button>
        <br />
        <br />
        <h5>Outline Variants</h5>
        <Button styles={styles.btn} outline color="primary">
          primary
        </Button>
        <Button styles={styles.btn} outline color="secondary">
          secondary
        </Button>
        <Button styles={styles.btn} outline color="success">
          success
        </Button>
        <Button styles={styles.btn} outline color="info">
          info
        </Button>
        <Button styles={styles.btn} outline color="warning">
          warning
        </Button>
        <Button styles={styles.btn} outline color="danger">
          danger
        </Button>
        <Button styles={styles.btn} outline color="black">
          black
        </Button>
        <Button styles={styles.btn} outline disabled>
          disabled
        </Button>
        <Button styles={styles.btn} outline color="link">
          link style
        </Button>
        <br />
        <br />
        <h5>Size Variants</h5>
        <Button styles={styles.btn} size="sm" color="primary" iconRight="icon-right">
          Small button
        </Button>
        <Button styles={styles.btn} color="primary" iconRight="icon-right">
          Default button
        </Button>
        <Button styles={styles.btn} size="lg" color="primary" iconRight="icon-right">
          Large button
        </Button>
        <br />
        <br />
        <Button styles={styles.btn} block color="primary" iconRight="icon-right">
          Block button
        </Button>
        <br />
        <br />
        <br />
      </Container>
    </div>
  )
}

export default Buttons
