import React, { Fragment } from 'react'
import {
  Buttons,
  Colors,
  Typography,
  Shadows,
  Forms,
  Components
} from './components'

const Styleguide = () => (
  <Fragment>
    <br />
    <br />
    <Typography />
    <Buttons />
    <Colors />
    <Shadows />
    <Forms />
    <Components />
  </Fragment>
)

export default Styleguide
