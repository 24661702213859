// @flow
import React, { Component } from 'react'
import { omitProps } from '../../../helpers/misc'
import BaseSlider from '@fcse/tsbob/dist/components/Slider'

class Slider extends Component<*> {
  static defaultProps = {
    mountResizeTimeout: 200,
    updateResizeTimeout: 5
  }

  componentDidMount() {
    this.forceSliderResize(this.props.mountResizeTimeout)
  }

  componentDidUpdate() {
    this.forceSliderResize(this.props.updateResizeTimeout)
  }

  getSliderProps = () => {
    return omitProps(this.props, [ 'mountResizeTimeout', 'updateResizeTimeout' ])
  }

  setSliderRef = (ref: Object) => {
    if (ref) {
      this.sliderRef = ref
    }
  }

  forceSliderResize = (resizeTimeout: number) => {
    setTimeout(() => {
      if (this.sliderRef) {
        this.sliderRef.forceSliderResize()
      }
    }, resizeTimeout)
  }

  sliderRef: Object

  render() {
    return <BaseSlider {...this.getSliderProps()} refDeep={this.setSliderRef} />
  }
}

export default Slider
