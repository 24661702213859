import React from 'react'
import Header from '../modules/Header'
import Footer from '../modules/Footer'
import Layout from '../components/Layout/index.js'
import Styleguide from '../components/Styleguide'
import SEO from '../components/seo'
import LocationContext from 'lib/locationContext'

const StyleguidePage = ({ location }: Object) => (
  <Layout footer={<Footer />}>
    <SEO title="Styleguide" />
    <LocationContext.Provider value={location}>
      <Header />
    </LocationContext.Provider>
    <Styleguide />
  </Layout>
)

export default StyleguidePage
