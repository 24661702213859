/** @flow */
import React from 'react'
import classNames from 'classnames'
// import { injectIntl } from 'react-intl'
import { applyStyling } from '../../../lib/styling'
// import messages from './ColorSwatch.lang'

import baseStyles from './ColorSwatch.scss'

type Props = {
  styles: Object,
  color: string,
  children: Object,
}

const defaultProps = {
  styles: {},
  color: 'black'
}

const ColorSwatch = ({ styles, color, children }: Props) => {
  return (
    <div className={classNames(styles.root, styles[color])}>
      <div className={styles.color} />
      <div className={styles.title} />
      <div className={styles.value} />
      <div className={styles.children}>{children}</div>
    </div>
  )
}

ColorSwatch.defaultProps = defaultProps

export default applyStyling(baseStyles)(ColorSwatch)
