/* eslint-disable max-len */

import React from 'react'
import Typo from '../../../components/basic/Typo'
import { Container, Row, Col } from '../../../components/basic/Grid'
import { Tabs, Tab } from '../../../components/basic/Tabs'
import styles from '../styleguide.scss'

const Typography = () => {
  return (
    <div>
      <Container>
        <h3>Typography</h3>
        <hr />
        <br />
        <Tabs initialActiveKey={1} navProps={{ pills: true, justified: true }}>
          <Tab eventKey={1} navTitle="Overview">
            <br />
            <Typo size="font-04" bold color="primary">
              We have usually 3 font-families in 8 different sizes and all
              theme-colors
            </Typo>
            <br />
            <br />
            <Row>
              <Col md="4">
                <Typo size="font-05" regular>
                  This is the regular style which is default
                </Typo>
                <br />
              </Col>
              <Col md="4">
                <Typo size="font-05" bold>
                  This is the bold style
                </Typo>
                <br />
              </Col>
              <Col md="4">
                <Typo size="font-05" accent>
                  This is the accent style
                </Typo>
                <br />
              </Col>
            </Row>
            <hr />
            <Row>
              <Col md="6">
                <Typo size="font-05" accent>
                  Default color inherits from body
                </Typo>
                <br />
                <Typo size="font-05" accent color="primary">
                  Typo with primary color
                </Typo>
                <br />
                <Typo size="font-05" accent color="secondary">
                  Typo with secondary color
                </Typo>
                <br />
                <Typo size="font-05" accent color="success">
                  Typo with success color
                </Typo>
                <br />
                <Typo size="font-05" accent color="info">
                  Typo with info color
                </Typo>
                <br />
                <Typo size="font-05" accent color="warning">
                  Typo with warning color
                </Typo>
                <br />
                <Typo size="font-05" accent color="danger">
                  Typo with danger color
                </Typo>
                <br />
              </Col>
              <Col md="6">
                <Typo size="font-05" accent color="black">
                  Typo with black color
                </Typo>
                <br />
                <Typo size="font-05" accent color="gray-dark">
                  Typo with gray-dark color
                </Typo>
                <br />
                <Typo size="font-05" accent color="gray-light">
                  Typo with gray-light color
                </Typo>
                <br />
                <div className={styles.bgBlack}>
                  <Typo size="font-05" accent color="white">
                    Typo with white color
                  </Typo>
                  <br />
                  <Typo size="font-05" accent color="white-dark">
                    Typo with white-dark color
                  </Typo>
                  <br />
                  <Typo size="font-05" accent color="white-light">
                    Typo with white-light color
                  </Typo>
                  <br />
                </div>
              </Col>
            </Row>
          </Tab>
          <Tab eventKey={2} navTitle="Regular">
            <br />
            <Typo size="font-08" regular color="primary">
              08 font-size made with Typo component
              <br />
              check line-height always in browser
            </Typo>
            <br />
            <Typo size="font-07" regular color="primary">
              07 font-size made with Typo component
              <br />
              check line-height always in browser
            </Typo>
            <br />
            <Typo size="font-06" regular color="primary">
              06 font-size made with Typo component
              <br />
              check line-height always in browser
            </Typo>
            <br />
            <Typo size="font-05" regular color="primary">
              05 font-size made with Typo component
              <br />
              check line-height always in browser
            </Typo>
            <br />
            <Typo size="font-04" regular color="primary">
              04 font-size made with Typo component
              <br />
              check line-height always in browser
            </Typo>
            <br />
            <Typo size="font-03" regular color="primary">
              03 font-size made with Typo component
              <br />
              check line-height always in browser
            </Typo>
            <br />
            <Typo size="font-02" regular color="primary">
              02 font-size made with Typo component
              <br />
              check line-height always in browser
            </Typo>
            <br />
            <Typo size="font-01" regular color="primary">
              01 font-size made with Typo component
              <br />
              check line-height always in browser
            </Typo>
            <br />
            <Typo size="font-00" regular color="primary">
              00 font-size made with Typo component
              <br />
              check line-height always in browser
            </Typo>
            <br />
          </Tab>
          <Tab eventKey={3} navTitle="Bold">
            <br />
            <Typo size="font-08" bold color="primary">
              08 font-size made with Typo component
              <br />
              check line-height always in browser
            </Typo>
            <br />
            <Typo size="font-07" bold color="primary">
              07 font-size made with Typo component
              <br />
              check line-height always in browser
            </Typo>
            <br />
            <Typo size="font-06" bold color="primary">
              06 font-size made with Typo component
              <br />
              check line-height always in browser
            </Typo>
            <br />
            <Typo size="font-05" bold color="primary">
              05 font-size made with Typo component
              <br />
              check line-height always in browser
            </Typo>
            <br />
            <Typo size="font-04" bold color="primary">
              04 font-size made with Typo component
              <br />
              check line-height always in browser
            </Typo>
            <br />
            <Typo size="font-03" bold color="primary">
              03 font-size made with Typo component
              <br />
              check line-height always in browser
            </Typo>
            <br />
            <Typo size="font-02" bold color="primary">
              02 font-size made with Typo component
              <br />
              check line-height always in browser
            </Typo>
            <br />
            <Typo size="font-01" bold color="primary">
              01 font-size made with Typo component
              <br />
              check line-height always in browser
            </Typo>
            <br />
            <Typo size="font-00" bold color="primary">
              00 font-size made with Typo component
              <br />
              check line-height always in browser
            </Typo>
            <br />
          </Tab>
          <Tab eventKey={4} navTitle="Accent">
            <br />
            <Typo size="font-08" accent color="primary">
              08 font-size made with Typo component
              <br />
              check line-height always in browser
            </Typo>
            <br />
            <Typo size="font-07" accent color="primary">
              07 font-size made with Typo component
              <br />
              check line-height always in browser
            </Typo>
            <br />
            <Typo size="font-06" accent color="primary">
              06 font-size made with Typo component
              <br />
              check line-height always in browser
            </Typo>
            <br />
            <Typo size="font-05" accent color="primary">
              05 font-size made with Typo component
              <br />
              check line-height always in browser
            </Typo>
            <br />
            <Typo size="font-04" accent color="primary">
              04 font-size made with Typo component
              <br />
              check line-height always in browser
            </Typo>
            <br />
            <Typo size="font-03" accent color="primary">
              03 font-size made with Typo component
              <br />
              check line-height always in browser
            </Typo>
            <br />
            <Typo size="font-02" accent color="primary">
              02 font-size made with Typo component
              <br />
              check line-height always in browser
            </Typo>
            <br />
            <Typo size="font-01" accent color="primary">
              01 font-size made with Typo component
              <br />
              check line-height always in browser
            </Typo>
            <br />
            <Typo size="font-00" accent color="primary">
              00 font-size made with Typo component
              <br />
              check line-height always in browser
            </Typo>
            <br />
          </Tab>
          <Tab eventKey={5} navTitle="HTML Defaults">
            <br />
            <Row className="Row">
              <Col md="6">
                <h1>h1 Headline goes here</h1>
                <h2>h2 Headline goes here</h2>
                <h3>h3 Headline goes here</h3>
                <h4>h4 Headline goes here</h4>
                <h5>h5 Headline goes here</h5>
                <h6>h6 Headline goes here</h6>
              </Col>
              <Col md="6">
                <h1>h1 Headline goes here</h1>
                <h3>h3 Headline goes here</h3>
                <p>
                  Aliqua non elit eiusmod voluptate. Fugiat mollit deserunt ut
                  in qui occaecat minim mollit exercitation esse commodo.{' '}
                  <strong>Something Bold</strong> Pariatur consequat aliqua
                  laboris mollit ex duis deserunt dolore veniam.{' '}
                  <a href="#">Link something</a>
                </p>
              </Col>
            </Row>
          </Tab>
        </Tabs>
        <br />
        <br />
      </Container>
    </div>
  )
}

export default Typography
