import React from 'react'
import classNames from 'classnames'
import { Container } from '../../../components/basic/Grid'
import styles from '../styleguide.scss'

const Shadows = () => {
  return (
    <div>
      <Container>
        <h3>Shadows</h3>
        <hr />
        <div className={styles.shadowsContainer}>
          <div className={classNames(styles.shadow, styles.shadow00)}>
            $shadow-00
          </div>
          <div className={classNames(styles.shadow, styles.shadow01)}>
            $shadow-01
          </div>
          <div className={classNames(styles.shadow, styles.shadow02)}>
            $shadow-02
          </div>
          <div className={classNames(styles.shadow, styles.shadow03)}>
            $shadow-03
          </div>
          <div className={classNames(styles.shadow, styles.shadow04)}>
            $shadow-04
          </div>
          <div className={classNames(styles.shadow, styles.shadow05)}>
            $shadow-05
          </div>
          <div className={classNames(styles.shadow, styles.shadow06)}>
            $shadow-06
          </div>
          <div className={classNames(styles.shadow, styles.shadow07)}>
            $shadow-07
          </div>
          <div className={classNames(styles.shadow, styles.shadow08)}>
            $shadow-08
          </div>
          <div className={classNames(styles.shadow, styles.shadow09)}>
            $shadow-09
          </div>
          <div className={classNames(styles.shadow, styles.shadow10)}>
            $shadow-10
          </div>
          <br />
          <br />
        </div>
        <br />
        <br />
        <br />
      </Container>
    </div>
  )
}

export default Shadows
