import React from 'react'
import { Container, Row, Col } from '../../../components/basic/Grid'
import _ from 'lodash'
import moment from 'moment'
import { withStateHandlers } from 'recompose'
import { Tabs, Tab } from '../../../components/basic/Tabs'
import { DateRangePicker } from '../../../components/basic/DatePicker'
import DateDropdown from '../../../components/basic/DateDropdown'
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from '../../../components/basic/Dropdown'
import { UncontrolledAlert } from '../../../components/basic/Alert'
import Carousel from '../../../components/basic/Carousel'
import Button from '../../../components/basic/Button'
import Modal from '../../../components/basic/Modal'
import OffCanvas from '../../../components/basic/OffCanvas'
import Pagination from '../../../components/basic/Pagination'
import Collapsible from '../../../components/basic/Collapsible'
import Tooltip from '../../../components/basic/Tooltip'
import Selectbar from '../../../components/basic/Selectbar'
import Slider from '../../../components/basic/Slider'
import Loader from '../../../components/basic/Loader'
import Checkbox from '../../../components/basic/Checkbox'
import Steps from '../../../components/basic/Steps'
import styles from '../styleguide.scss'

type Props = {
  modalVisible?: boolean,
  closeModal?: any,
  openModal?: any
}

type State = {
  dropdownOpen: boolean
}

class Components extends React.Component<Props, State> {
  state = {
    dropdownOpen: false
  }

  toggle = () => {
    this.setState({ dropdownOpen: !this.state.dropdownOpen })
  }

  render() {
    const { modalVisible, closeModal, openModal } = this.props

    const { dropdownOpen } = this.state

    const options = _.map(_.range(1, 10), value => {
      const strValue = value
      return {
        value: strValue,
        label: 'Label ' + strValue
      }
    })

    const sliderOptions: Array<Option> = [
      {
        value: 25,
        label: '25'
      },
      {
        value: 150,
        label: '150'
      },
      {
        value: 400,
        label: '400'
      },
      {
        value: 1000,
        label: '1000'
      },
      {
        value: 1500,
        label: '1500'
      },
      {
        value: 2000,
        label: '2000'
      },
      {
        value: 3000,
        label: '3000'
      },
      {
        value: -1,
        label: 'no limit'
      }
    ]

    const selectbarOptions: Array<Option> = [
      {
        value: 1,
        label: '1 star'
      },
      {
        value: 2,
        label: '2 stars'
      },
      {
        value: 3,
        label: '3 stars'
      },
      {
        value: 4,
        label: '4 stars'
      },
      {
        value: 5,
        label: '5 stars'
      }
    ]

    const getItems = count =>
      _.map(_.range(0, count), i => (
        <img key={i} alt="" src={'http://placehold.it/600x400/' + i}/>
      ))

    const offcanvasTriggerRenderer = (onClick: Function, isOpen: boolean) => (
      <Button onClick={onClick}>
        {isOpen ? 'Click to close OffCanvas' : 'Click to open OffCanvas'}
      </Button>
    )

    return (
      <div>
        <Container>
          <h3>Components</h3>
          <hr/>
          <h5>Dropdown</h5>
          {/* Static classes are still not working */}
          <Dropdown isOpen={dropdownOpen} toggle={this.toggle}>
            <DropdownToggle nav>
              Test
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem active>
                <div>Test</div>
              </DropdownItem>
              <DropdownItem>
                <div>Test</div>
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
          <br/>
          <br/>
          <h5>Tooltip</h5>
          <Tooltip content="Test bottom tooltip" placement="bottom">
            <a>Top tooltip test</a>
          </Tooltip>
          <br/>
          <br/>
          <h5>Collapsible</h5>
          <Collapsible trigger="Header">
            <p>
              A Simple collapsible
              <br/>
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
              nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
              erat, sed diam voluptua. At vero eos et accusam et justo duo dolores
              et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est
              Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur
              sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore
              et dolore magna aliquyam erat, sed diam voluptua. At vero eos et
              accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren,
              no sea takimata sanctus est Lorem ipsum dolor sit amet.
            </p>
          </Collapsible>
          <br/>
          <h5>Steps</h5>
          <Steps steps={[ 'Step0', 'Step1', 'Step2' ]} value={1}/>
          <br/>
          <br/>
          <Row>
            <Col lg="6">
              <h5>Tabs default</h5>
              <Tabs initialActiveKey={1}>
                <Tab eventKey={1} navTitle="Tab 01 active">
                  Tabcontent goes here
                </Tab>
                <Tab eventKey={2} navTitle="Tab 02"/>
                <Tab eventKey={3} navTitle="Tab 03"/>
                <Tab eventKey={4} navTitle="Tab 04 disabled" disabled/>
              </Tabs>
            </Col>
            <Col lg="6">
              <h5>Tabs default justified</h5>
              <Tabs
                initialActiveKey={1}
                navProps={{ tabs: true, justified: true }}
              >
                <Tab eventKey={1} navTitle="Tab 01 active">
                  Tabcontent goes here
                </Tab>
                <Tab eventKey={2} navTitle="Tab 02"/>
              </Tabs>
            </Col>
          </Row>
          <br/>
          <br/>
          <Row>
            <Col lg="6">
              <h5>Tabs pills</h5>
              <Tabs initialActiveKey={1} navProps={{ pills: true }}>
                <Tab eventKey={1} navTitle="Tab 01 active">
                  Tabcontent goes here
                </Tab>
                <Tab eventKey={2} navTitle="Tab 02"/>
                <Tab eventKey={3} navTitle="Tab 03"/>
                <Tab eventKey={4} navTitle="Tab 04 disabled" disabled/>
              </Tabs>
            </Col>
            <Col lg="6">
              <h5>Tabs pills justified</h5>
              <Tabs
                initialActiveKey={1}
                navProps={{ pills: true, justified: true }}
              >
                <Tab eventKey={1} navTitle="Tab 01 active">
                  Tabcontent goes here
                </Tab>
                <Tab eventKey={2} navTitle="Tab 02"/>
              </Tabs>
            </Col>
          </Row>
          <br/>
          <br/>
          <div>
            <h5>Carousel</h5>
            <Carousel
              settings={{
                swipe: true,
                responsive: [
                  {
                    breakpoint: 500,
                    settings: {
                      slidesToShow: 1,
                      slidesToScroll: 1
                    }
                  },
                  {
                    breakpoint: 991,
                    settings: {
                      slidesToShow: 3,
                      slidesToScroll: 1
                    }
                  },
                  {
                    breakpoint: 1199,
                    settings: {
                      slidesToShow: 4,
                      slidesToScroll: 1
                    }
                  },
                  {
                    breakpoint: 1439,
                    settings: {
                      slidesToShow: 5,
                      slidesToScroll: 1,
                      swipe: true
                    }
                  },
                  {
                    breakpoint: 9999,
                    settings: {
                      slidesToShow: 6,
                      slidesToScroll: 1,
                      swipe: false
                    }
                  }
                ]
              }}
            >
              {getItems(7)}
            </Carousel>
          </div>
          <br/>
          <br/>
          <br/>
          <Row>
            <Col sm="6" lg="4">
              <h5>Datepicker</h5>
              <DateRangePicker
                fromMonth={moment()
                  .subtract(2, 'months')
                  .toDate()}
                toMonth={moment()
                  .add(2, 'months')
                  .toDate()}
              />
            </Col>
            <Col sm="6" lg="8">
              <h5>Date Dropdown</h5>
              <DateDropdown
                options={options}
                value="2015-12-23"
                selectProps={{
                  hideSeparator: true
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col sm="6" lg="4" className={styles.modalContainer}>
              <h5>Modal</h5>
              <Button onClick={openModal}>Click to open a Modal</Button>
              <Modal
                visible={modalVisible}
                onClose={closeModal}
                title="Modal Title"
                footer={
                  <>
                    <Button outline color="gray-dark">
                      Cancel
                    </Button>
                    <Button>OK</Button>
                  </>
                }
              >
                Modal content goes here
              </Modal>
            </Col>
            <Col sm="6" lg="4" className={styles.offcanvasContainer}>
              <h5>OffCanvas</h5>
              <OffCanvas
                customCrossIcon="icon-close"
                triggerRenderer={offcanvasTriggerRenderer}
                title='Off Canvas Title'
              >
                <div>Test content</div>
              </OffCanvas>
            </Col>
          </Row>
          <br/>
          <div className={styles.paginationContainer}>
            <h5>Pagination</h5>
            <Pagination
              previousLabel="Prev"
              initialSelected={5}
              results={200}
              resultsPerPage={10}
            />
          </div>
          <br/>
          <Row className={styles.paginationContainer}>
            <Col sm="6">
              <h5>Selectbar</h5>
              <Selectbar value={2} options={selectbarOptions}/>
            </Col>
            <Col sm="6">
              <h5>Slider simple</h5>
              <Slider options={sliderOptions} value={[ 150 ]}/>
              <br/>
              <h5>Slider range</h5>
              <br/>
              <Slider options={sliderOptions} value={[ 150, 2000 ]}/>
              <br/>
              <br/>
              <h5>Slider handle text outside</h5>
              <br/>
              <Slider
                options={sliderOptions}
                renderHandleLabelsOutside
                value={[ 150, 2000 ]}
              />
              <br/>
              <h5>Slider handle text outside on top</h5>
              <br/>
              <Slider
                options={sliderOptions}
                renderHandleLabelsOutside
                renderHandleLabelsOnTop
                value={[ 150, 2000 ]}
              />
            </Col>
          </Row>
          <br/>
          <br/>
          <br/>
          <Row className={styles.alertRow}>
            <Col sm="4">
              <h5>Alerts / Toasts</h5>
              <UncontrolledAlert icon="icon-error" color="success">
                <h5>Notification</h5>
              </UncontrolledAlert>
              <br/>
              <UncontrolledAlert icon="icon-error" color="warning">
                <h5>Notification</h5>
              </UncontrolledAlert>
              <br/>
              <UncontrolledAlert icon="icon-error" color="danger">
                <h5>Notification</h5>
              </UncontrolledAlert>
              <br/>
              <UncontrolledAlert icon="icon-error" color="info">
                <h5>Notification</h5>
              </UncontrolledAlert>
              <br/>
              <UncontrolledAlert color="success">
                <h5>Notification</h5>
                <p>This is an success Alert</p>
              </UncontrolledAlert>
              <br/>
              <UncontrolledAlert color="warning">
                <h5>Notification</h5>
                <p>This is an warning Alert</p>
              </UncontrolledAlert>
              <br/>
              <UncontrolledAlert color="danger">
                <h5>Notification</h5>
                <p>This is an danger Alert</p>
              </UncontrolledAlert>
              <br/>
              <UncontrolledAlert color="info">
                <h5>Notification</h5>
                <p>This is an info Alert</p>
              </UncontrolledAlert>
              <br/>
              <br/>
            </Col>
            <Col sm="4">
              <h5>Inverted colors</h5>
              <UncontrolledAlert
                icon="icon-danger"
                color="success"
                invertedColors
              >
                <h5>Notification</h5>
              </UncontrolledAlert>
              <br/>
              <UncontrolledAlert
                icon="icon-danger"
                color="warning"
                invertedColors
              >
                <h5>Notification</h5>
              </UncontrolledAlert>
              <br/>
              <UncontrolledAlert icon="icon-error" color="danger" invertedColors>
                <h5>Notification</h5>
              </UncontrolledAlert>
              <br/>
              <UncontrolledAlert icon="icon-error" color="info" invertedColors>
                <h5>Notification</h5>
              </UncontrolledAlert>
              <br/>
              <UncontrolledAlert color="success" invertedColors>
                <h5>Notification</h5>
                <p>This is an success Alert with inverted colors</p>
              </UncontrolledAlert>
              <br/>
              <UncontrolledAlert color="warning" invertedColors>
                <h5>Notification</h5>
                <p>This is an warning Alert with inverted colors</p>
              </UncontrolledAlert>
              <br/>
              <UncontrolledAlert color="danger" invertedColors>
                <h5>Notification</h5>
                <p>This is an danger Alert with inverted colors</p>
              </UncontrolledAlert>
              <br/>
              <UncontrolledAlert color="info" invertedColors>
                <h5>Notification</h5>
                <p>This is an info Alert with inverted colors</p>
              </UncontrolledAlert>
              <br/>
              <br/>
            </Col>
          </Row>
          <Row className={styles.loaderRow}>
            <Col lg="6">
              <h5>Loader</h5>
              <div
                className={styles.loaderRowinner}
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center'
                }}
              >
                <Loader color="primary" size="xs"/>
                <Loader color="secondary" size="sm"/>
                <Loader color="success" size="md"/>
                <Loader color="danger" size="lg"/>
                <Loader color="warning" size="xl"/>
                <Loader color="gray-light" size="xxl"/>
                <div style={{ background: 'black' }}>
                  <Loader color="white" size="xxl"/>
                </div>
                <Loader color="primary" icon="hotel" size="xxl"/>
              </div>
              <br/>
            </Col>
            <Col lg="6">
              <h5>Loader absolute with overlay</h5>
              <Row>
                <Col sm="6">
                  <div
                    className={styles.loaderImageContainer}
                    style={{ position: 'relative', display: 'inline-block' }}
                  >
                    <img
                      style={{ maxWidth: '100%' }}
                      src="https://bit.ly/2BZ2Cbf"
                      alt=""
                    />
                    <Loader
                      color="primary"
                      icon="heart-filled"
                      size="xxl"
                      absolute
                      overlay="white"
                    />
                  </div>
                </Col>
                <Col sm="6">
                  <div
                    className={styles.loaderImageContainer}
                    style={{ position: 'relative', display: 'inline-block' }}
                  >
                    <img
                      style={{ maxWidth: '100%' }}
                      src="https://bit.ly/2BZ2Cbf"
                      alt=""
                    />
                    <Loader
                      color="white"
                      icon="plane"
                      size="xxl"
                      absolute
                      overlay="black"
                    />
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
          <div>
            <h5>Loader absolute with overlay as mixin</h5>
            <Row>
              <Col sm="4">
                <div
                  className={styles.loaderImageContainer}
                  style={{ position: 'relative', display: 'inline-block' }}
                >
                  <img
                    style={{ maxWidth: '100%' }}
                    src="https://bit.ly/2BZ2Cbf"
                    alt=""
                  />
                  <div className={styles.loaderOverlay}>
                    <div className={styles.loaderMixin}/>
                  </div>
                </div>
              </Col>
              <Col sm="4">
                <div
                  className={styles.loaderImageContainer}
                  style={{ position: 'relative', display: 'inline-block' }}
                >
                  <img
                    style={{ maxWidth: '100%' }}
                    src="https://bit.ly/2BZ2Cbf"
                    alt=""
                  />
                  <div className={styles.loaderOverlayBlack}>
                    <div className={styles.loaderMixinWhite}/>
                  </div>
                </div>
              </Col>
              <Col sm="4">
                <div
                  className={styles.loaderImageContainer}
                  style={{ position: 'relative', display: 'inline-block' }}
                >
                  <img
                    style={{ maxWidth: '100%' }}
                    src="https://bit.ly/2BZ2Cbf"
                    alt=""
                  />
                  <div className={styles.loaderOverlayCustom}>
                    <div className={styles.loaderMixinCustom}/>
                  </div>
                </div>
              </Col>
              <Col sm="4">
                <Checkbox className={styles.loaderCheckbox}>
                  <span className={styles.loaderCheckboxLabel}>
                    trigger loading
                  </span>
                  <div
                    className={styles.loaderImageContainer}
                    style={{ position: 'relative', display: 'inline-block' }}
                  >
                    <img
                      style={{ maxWidth: '100%' }}
                      src="https://bit.ly/2BZ2Cbf"
                      alt=""
                    />
                    <div className={styles.loaderOverlayFadeIn}>
                      <div className={styles.loaderMixinFadeIn}/>
                    </div>
                  </div>
                </Checkbox>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    )
  }
}

const enhance = withStateHandlers(
  { modalVisible: false },
  {
    closeModal: () => () => ({ modalVisible: false }),
    openModal: () => () => ({ modalVisible: true })
  }
)

export default enhance(Components)
