import React from 'react'
import { Container, Row, Col } from '../../../components/basic/Grid'
import {
  Form,
  FormFeedback,
  FormGroup,
  FormText,
  Input,
  InputGroup,
  InputGroupAddon,
  Label,
  SelectCustom
} from '../../../components/basic/Forms'
import Checkbox from '../../../components/basic/Checkbox'
import Checklist from '../../../components/basic/Checklist'
import _ from 'lodash'
import RadioButtons from '../../../components/basic/RadioButtons'
import Select from '../../../components/basic/Select'
import CheckboxSwitch from '../../../components/basic/CheckboxSwitch'

const Forms = () => {
  const options = _.map(_.range(1, 4), value => {
    const strValue = String(value)
    return { value: strValue, label: 'Checklist Item ' + strValue }
  })

  const radioOptions = [
    { value: 'DUS', label: 'Duesseldorf' },
    { value: 'HAM', label: 'Hamburg' },
    { value: 'FRA', label: 'Frankfurt' },
    { value: 'TXL', label: 'Tegel' },
    { value: 'DRS', label: 'Dresden' },
    { value: 'CGN', label: 'Koln Bonn' }
  ]

  const defaultValue = 'DRS'

  return (
    <div>
      <Container>
        <h3>Forms</h3>
        <hr />
        <Row>
          <Col sm="6" md="4">
            <Form>
              <FormText tag="p">Form with Form Text and Feedback</FormText>
              <Label for="input">Label</Label>
              <Input id="input" placeholder="Input placeholder" />
              <FormFeedback valid>Feedback</FormFeedback>
            </Form>
            <br />
          </Col>
          <Col sm="6" md="4">
            <Form>
              <Label for="input">Input with Addon</Label>
              <InputGroup>
                <Input id="input" placeholder="Input placeholder" />
                <InputGroupAddon>Text</InputGroupAddon>
              </InputGroup>
            </Form>
          </Col>
          <Col sm="6" md="4">
            <Form>
              <Label for="select">Custom Select</Label>
              <SelectCustom>
                <Input type="select" id="select">
                  <option>Choose option</option>
                  <option>1</option>
                  <option>2</option>
                </Input>
              </SelectCustom>
            </Form>
          </Col>
        </Row>
        <Row>
          <Col md="6">
            <h5>Form with multiple inputs</h5>
            <Form>
              <FormGroup>
                <Label for="input">Label</Label>
                <Input id="input" placeholder="Input placeholder" />
              </FormGroup>
              <FormGroup>
                <Label for="select">Custom Select</Label>
                <SelectCustom>
                  <Input type="select" id="select">
                    <option>Choose option</option>
                    <option>1</option>
                    <option>2</option>
                  </Input>
                </SelectCustom>
              </FormGroup>
            </Form>
          </Col>
          <br />
          <br />
          <Col md="6">
            <h5>Inline Form</h5>
            <Form inline>
              <Label for="input">Email:</Label>
              <Input id="input" placeholder="Input placeholder" />
              <Label for="input">Username:</Label>
              <Input id="input" placeholder="Input placeholder" />
            </Form>
          </Col>
        </Row>
        <br />
        <br />
        <Row>
          <Col sm="4" md="3">
            <h5>Checkbox customized</h5>
            <Checkbox>Check me out baby!</Checkbox>
            <Checkbox>Yeah! Also check this out, dude</Checkbox>
            <br />
            <Checkbox disabled value="1">
              I am disabled
            </Checkbox>
            <br />
            <Checkbox intermediate checked value="1">
              I am intermediate
            </Checkbox>
            <br />
            <br />
            <Checklist multiple options={options} />
            <br />
            <CheckboxSwitch>Checkbox switch</CheckboxSwitch>
            <br />
            <CheckboxSwitch onText="Yes" offText="No">
              Checkbox switch
            </CheckboxSwitch>
          </Col>
          <Col sm="4" md="3">
            <h5>Radio Button customized</h5>
            <RadioButtons options={radioOptions} value={defaultValue} />
            <br />
            <CheckboxSwitch checked>Checkbox switch</CheckboxSwitch>
            <br />
            <CheckboxSwitch onText="Yes" offText="No" checked>
              Checkbox switch
            </CheckboxSwitch>
          </Col>
          <Col sm="4" md="3">
            <h5>Advanced custom select</h5>
            <FormGroup>
              <Label for="input">Label:</Label>
              <Select
                options={options}
                isSearchable={false}
                hideSeparator
                iconClass="icon-adult"
              />
            </FormGroup>
            <FormGroup>
              <Label for="input">Searchable Mulitselect:</Label>
              <Select
                options={radioOptions}
                isSearchable
                isMulti
                hideSeparator
              />
            </FormGroup>
          </Col>
        </Row>
        <br />
        <br />
      </Container>
    </div>
  )
}

export default Forms
