// TO DO : Someone needs to cleanup code style

import React, { PureComponent } from 'react'
import classNames from 'classnames'
import _ from 'lodash'
import { applyStyling } from '../../../lib/styling'
import baseStyles from './Typo.scss'

const screenSizes = [ '', 'xs', 'sm', 'md', 'lg', 'xl', 'xxl' ]
const attributes = [ 'size', 'color', 'bold', 'accent', 'regular' ]
const propsToClear = [ ...screenSizes, ...attributes, 'tag', 'styles' ]

type PropsBase = {
  tag?: string,
  size?: any,
  color?: string,
  bold?: boolean,
  accent?: boolean,
  italic?: boolean,
  regular?: boolean,
}

type Props = {
  styles: Object | Array<Object> | string,
  ...PropsBase,
  xs?: PropsBase,
  sm?: PropsBase,
  md?: PropsBase,
  lg?: PropsBase,
  xl?: PropsBase,
  xxl?: PropsBase,
}

class Typo extends PureComponent<Props> {
  static defaultProps = {
    tag: 'div',
    styles: {}
  }

  props: Props

  clearProps = () => {
    return Object.keys(this.props)
      .filter(key => !propsToClear.includes(key))
      .reduce((acc, item) => {
        return {
          ...acc,
          [item]: this.props[item]
        }
      }, {})
  }

  makePath = (size, attr) => {
    return `${size ? `${size}.` : ''}${attr}`
  }

  makeClassNames = () => {
    const { styles } = this.props
    const classes = []
    screenSizes.forEach(scrSize => {
      {
        /* $FlowFixMe: ignore */
      }
      if (this.props[scrSize] || scrSize === '') {
        attributes.forEach(attr => {
          const attrValue = _.get(this.props, this.makePath(scrSize, attr), '')
          if (typeof attrValue === 'boolean') {
            {
              /* $FlowFixMe: ignore */
            }
            classes.push(styles[`${scrSize ? `${scrSize}-` : ''}${attr}`])
          } else if (attrValue) {
            {
              /* $FlowFixMe: ignore */
            }
            classes.push(
              styles[`${scrSize ? `${scrSize}-` : ''}${attr}-${attrValue}`]
            )
          }
          // TODO fix this condition (it's always false)
          // if (typeof attr === 'color') {
          //   {/* $FlowFixMe: ignore */}
          //   classes.push(styles['text'])
          // }
        })
      }
    })
    return classes
  }

  render() {
    const { styles, tag } = this.props
    {
      /* $FlowFixMe: ignore */
    }
    return React.createElement(tag, {
      /* $FlowFixMe: ignore */
      className: classNames(styles.root, ...this.makeClassNames()),
      ...this.clearProps()
    })
  }
}

export default applyStyling(baseStyles)(Typo)
