// @flow
import React from 'react'
import classNames from 'classnames'
import { applyStyling } from '../../../lib/styling'
import baseStyles from './Loader.scss'

type Props = {
  styles: Object,
  size: string,
  color: string,
  icon: string,
  absolute: boolean,
  overlay: string,
}

const Loader = ({ styles, size, color, icon, absolute, overlay }: Props) => (
  <div
    className={classNames(
      styles.root,
      size && styles['is-' + size],
      color && styles['is-' + color],
      icon && styles['has-icon'],
      absolute && styles['is-absolute'],
      overlay && styles['is-absolute'],
      overlay && styles['has-' + overlay + '-overlay']
    )}
  >
    <div className={styles.loaderContainer}>
      <div
        className={classNames(
          styles.loader,
          size && styles['is-' + size],
          color && styles['is-' + color]
        )}
      />
      {icon && <div className={classNames(styles.icon, 'icon-' + icon)} />}
    </div>
  </div>
)

export default applyStyling(baseStyles)(Loader)
